const NextI18Next = require('next-i18next').default

const NextI18NextInstance = new NextI18Next({
  defaultLanguage: 'en',
  otherLanguages: ['fr-CA', 'cy'],
  localePath: typeof window !== 'object' ? 'src/static/locales' : 'static/locales',
  keySeparator: false,
  browserLanguageDetection: true,
  serverLanguageDetection: true,
  detection: {
    order: ['htmlTag']
  }
})

const Module = module.exports = NextI18NextInstance

Module.appWithTranslation = NextI18NextInstance.appWithTranslation
Module.withTranslation = NextI18NextInstance.withTranslation
